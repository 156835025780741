import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  stateCitiesFailure,
  stateCitiesRequest,
  stateCitiesSuccess,

  cityListRequest,
  cityListSuccess,
  cityListFailure,
} from "../features/fixtureSlice";

interface CustomError extends Error {
  response?: {
    data: {
      detail: string;
    };
  };
}

export const fetchStateWithCity = createAsyncThunk(
  "category/fetchStateWithCity",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(stateCitiesRequest());
      const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/fixtures/state_and_cities/`);
      thunkAPI.dispatch(stateCitiesSuccess(data));
    } catch (error) {
      const customError = error as CustomError;

      thunkAPI.dispatch(
        stateCitiesFailure(
          customError.response && customError.response.data.detail
            ? customError.response.data.detail
            : customError.message
        )
      );
    }
  }
);



export const fetchCityList = createAsyncThunk(
  "fetchCityList",
  async (page: number, thunkAPI) => {
    try {
      thunkAPI.dispatch(cityListRequest());
      const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/fixtures/city_list/?page=${page}`);
      thunkAPI.dispatch(cityListSuccess(data));
    } catch (error) {
      const customError = error as CustomError;

      thunkAPI.dispatch(
        cityListFailure(
          customError.response && customError.response.data.detail
            ? customError.response.data.detail
            : customError.message
        )
      );
    }
  }
);
