import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import { TestimonialListState } from "../../state/testimonialState";

export const testimonialApi = createApi({
    reducerPath: "testimonialApi",
    baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_BASE_URL}/testimonials` }),
    endpoints: (builder) => ({
        getTestimonials: builder.query<TestimonialListState, void>({
            query: () => `/`,
        }),
    }),
    refetchOnMountOrArgChange: false, // Applies globally to all endpoints
    keepUnusedDataFor: 300,
});


export const { useGetTestimonialsQuery } = testimonialApi;