import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setCredentials, logOut } from "../../features/authSlice";
import { UserInfoState } from "../../features/authSlice";


const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BASE_URL}/accounts`,
  credentials: "include",
  prepareHeaders: (headers, { getState }: any) => {
    const token = getState().auth.token;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 403) {
    console.log("sending request token");

    // send refresh token to get new token
    const refreshResult = await baseQuery("/refresh_token", api, extraOptions);
    console.log(refreshResult);
    if (refreshResult?.data) {
      const userData: UserInfoState = {
        ...refreshResult.data,
        ...api.getState().auth.user_info, // This assumes you have a user object in your state
      };
      api.dispatch(setCredentials(userData));

      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }
  return result;
};

// const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
//     let result = await baseQuery(args, api, extraOptions)

//     if (result?.error?.status === 403) {
//         console.log("sending request token")

//         // send refresh token to get new token
//         const refreshResult = await baseQuery('/refresh_token', api, extraOptions)
//         console.log(refreshResult);
//         if (refreshResult?.data) {
//             // get user from state which is already there if user is logged in
//             const user = api.getState().auth.user
//             // store a new token
//             api.dispatch(setCredentials({ ...refreshResult.data, user }))

//             result = await baseQuery(args, api, extraOptions)
//         }
//         else {
//             api.dispatch(logOut())
//         }

//     }
//     return result
// }

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
});
