import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import { DailyUsersState } from "../../state/authState";

export const orderApi = createApi({
    reducerPath: "orderApi",
    baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_BASE_URL}/pickuporders` }),
    endpoints: (builder) => ({
        getTotalCompletedOrders: builder.query<DailyUsersState, void>({
            query: () => `/get_orders_details/`,
        }),
    }),
    refetchOnMountOrArgChange: false, // Applies globally to all endpoints
    keepUnusedDataFor: 300,
});


export const { useGetTotalCompletedOrdersQuery } = orderApi;