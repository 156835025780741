import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import { ExcitingOfferListState } from "../../state/excitingOfferState";

export const excitingOfferApi = createApi({
    reducerPath: "excitingOfferApi",
    baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_BASE_URL}/catalogue` }),
    endpoints: (builder) => ({
        getExcitingOffers: builder.query<ExcitingOfferListState, void>({
            query: () => `/exciting_offer_list/`,
        }),
    }),
    refetchOnMountOrArgChange: false, // Applies globally to all endpoints
    keepUnusedDataFor: 300,
});


export const { useGetExcitingOffersQuery } = excitingOfferApi;