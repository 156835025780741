import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import { CategoryListState } from "../../state/categoryState";

export const categoryApi = createApi({
    reducerPath: "categoryApi",
    baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_BASE_URL}/categories` }),
    tagTypes: ["Categories"],
    endpoints: (builder) => ({
        getCategories: builder.query<CategoryListState, void>({
            query: () => `/`,
            providesTags: ["Categories"],
        }),
    }),
    refetchOnMountOrArgChange: false, // Applies globally to all endpoints
    keepUnusedDataFor: 300,
});


export const { useGetCategoriesQuery } = categoryApi;