import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import { AppReviewState } from "../../state/seoState";

export const seoApi = createApi({
    reducerPath: "seoApi",
    baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_BASE_URL}/seo` }),
    endpoints: (builder) => ({
        getReviews: builder.query<AppReviewState, void>({
            query: () => `/review_list/`,
        }),
    }),
    refetchOnMountOrArgChange: false, // Applies globally to all endpoints
    keepUnusedDataFor: 300,
});


export const { useGetReviewsQuery } = seoApi;